.photo {
    margin-bottom: 20px;
    
    &__img {
        width: 100%;
        height:0;
        padding-bottom: 60%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            top:50%;
            left:50%;
            transform: translate3d(-50%,-50%,0);
            width: 100%;
            min-height:100%;
        }
    }

    &__caption {
        margin:0;
        font-size: 12px;
        color: $darkGray;
        background: $lightestGray;
        padding: 5px;
    }
}

.editorial {

    .breadcrumb {
        margin-top: 40px;
    }

    &__block {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__text {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    &__form {
        $f: &;

        &__group {
            padding: 25px 0 10px;
            position: relative;

            &--checkbox {
                display: flex;

                label {
                    padding-left: 10px;
                    font-size: 12px;
                }
            }
        }

        &__label {
            display: block;
            font-weight: 700;
            font-size: 14px;
            color: $altColor;
            position: absolute;
            opacity: 0;
            top: 0;
            transform: translateY(100%);
            @include trans;

            & + span {
                @include trans;
                position: absolute;
                display: block;
                bottom: 10px;
                height: 2px;
                left:0;
                width: 0;
                background: $altColor;
            }
        }

        &__input {
            display: block;
            height: 40px;
            line-height: 40px;
            border:none;
            width: 100%;
            padding: 0 10px;
            background: $lightestGray;
            border-bottom: 1px solid $white;
            font-size: 18px;

            &--textarea {
                height: 120px;
            }

            &:focus {
                outline: none;
                box-shadow: none;
                background: transparent;

                & + #{$f}__label {
                    opacity: 1;
                    transform: translateY(0);

                    & + span {
                        width: 100%;
                    }
                }

                &::placeholder {
                    opacity: 0;
                }


            }

            &::placeholder {
                @include trans;
            }
        }

        &__submit {
            @include trans;
            background: $mainColor;
            height: 40px;
            border: none;
            padding: 0 30px;
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
            font-family: $altFont;
            font-size: 14px;
            line-height: 40px;

            &:hover {
                background: $mainColor - #333;
            }
        }
    }
}

.nota {
    &__hero {
        &__heading {
            background: $lightestGray;
            padding: 15px;
            font-size: 12px;
            margin-bottom: 20px;
            font-weight: 00;

            i {
                margin-right: 10px;
            }
        }

        &__seccion {
            color: $red;
            font-size: 18px;
            display: block;
            font-weight: 700;
            font-family: $altFont;
        }

        &__title {
            color: $altColor;
            margin: 0;
            padding: 20px 0;
            font-family: $altFont;
        }

        &__summary {
            margin: 0 0 40px;
            color: $midGray;
            font-weight: 300;
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__body {
        padding-bottom: 40px;

        &__sidebar {
            display: none;

            @include mq(md){
                display: block;
            }

            &__newsletter {
                background: $lightestGray;
                padding: 15px;

                &__title {
                    font-family: $altFont;
                    font-weight: 600;
                    color: $altColor;
                }

                &__input {
                    display: block;
                    width: 100%;
                    height: 40px;
                    border: none;
                    padding: 0 15px;
                    margin-bottom: 15px;
                }

                &__submit {
                    background: $mainColor;
                    font-family: $mainFont;
                    font-size: $xs;
                    font-weight: 700;
                    border:none;
                    height: $base;
                    line-height: $base;
                    padding: 0 20px;
                    color: $white;
                }
            }
        }
    }

    &__author {
        display: flex;
        align-items: center;

        &__img {
            width: 60px;
            height: 60px;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid $lightGray;

            @include mq(md){
                width: 100px;
                height: 100px;
            }

            img {
                position: absolute;
                top:0;
                height: 100%;
                width: auto;
                left: 50%;
                transform: translateX(-50%);
                filter: grayscale(100%);
            }
        }

        &__data {
            padding-left: 20px;

            &__name {
                font-weight: 300;
                margin: 0;

                span {
                    display: inline-block;
                    padding-right: 10px;
                    border-right: 1px solid $lightGray;
                    margin-right: 10px;
                }
            }
        }
    }

    &__share {

        &__col {

            @include mq(sm) {
                display: flex;
                justify-content: flex-end;
            }
        }

        &__actions {
            display: flex;
            padding: 15px 0;

            li {
                margin-left: 15px;
            }

            a {
                background: $lightGray;
                color: $midGray;
                display: block;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;


                &:hover {
                    background: $mainColor;
                    color: $white;
                }
            }
        }

        &__comments {
            text-align: right;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 10px;

        &--first {
            padding-top: 20px;

            &::first-letter {
                color: $altColor;
                float: left;
                font-size: 5em;
                margin: 0 5px 20px 0;
                font-weight: 600;
            }
        }
    }

    &__taxonomy {
        background: $lightestGray;
        padding: 15px;

        &__title {
            color: $mainColor;
            font-family: $altFont;
            font-weight: 700;
        }

        &__list {

            li {

                display: inline-block;

                a {
                    display: inline-block;
                    padding: 3px 0;
                    color: $midGray;
                    font-size: 14px;

                    &:hover {
                        background: $lightGray;
                        padding: 3px 5px;
                    }
                }

                &:not(:last-child) {
                    &:after {
                        content: ' - ';
                        margin: 0 5px;
                    }
                }
            }
        }
    }

    &__related {
        &__title {
            background: $lightestGray;
            padding: 10px;
            margin: 0;

            &--red {
                background: $mainColor;
                color: $white;
            }
        }
    }

    &__facebook {
        padding-top: 40px;
        padding-bottom: 40px;
        background: $lightestGray;
    }

    &__embed {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $lightestGray;
        position: relative;
        overflow: hidden;
        max-width: 500px;
        min-height: 420px;
        margin: auto;

        @include mq(sm) {
            min-height: 490px;
        }

        &__item {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            width:100%;
            height: 100%;
            margin: auto;
        }
    }
}

.ciudades {
    background: $lightestGray;
    padding: 15px;

    &__title {
        color: $altColor;
        padding: 0 0 15px;
        margin: 0;
        border-bottom: 1px solid $altColor;

        br {
            display: none;
        }

        @include mq(sm){
            text-align: right;
            padding: 0;
            border-bottom: none;

            br {
                display: block;
            }
        }
    }

    &__list {
        columns: 2;
        padding-top: 15px;

        @include mq(sm) {
            columns: 3;
            padding-left: 20px;
            border-left: 1px solid $altColor;
            padding-top: 0;
        }

        @include mq(md) {
            columns: 4;
        }

        li {
            margin-bottom: 5px;
        }

        a {
            color: $darkGray;

            &:hover {
                color: $altColor;
            }
        }
    }
}
